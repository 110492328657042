<template>
  <div class="impressumPage">
    <div class="section first-section">
      <div class="container">
        <div class="row">
          <div class="col-md-12 ml-auto mr-auto">
            <h2 class="title">Unsere AGB</h2>
            <p>
              Allgemeine Geschäftsbedingungen des Zahntechniker-Handwerks<br/>
              <br/>
              1. Allgemeines<br/>
              1.1. Aufträge für zahntechnische Leistungen werden nach den allgemeinen Geschäftsbedingungen des Zahntechniker-Handwerks ausgeführt. Die allgemeinen Geschäftsbedingungen gelten für die gesamte Dauer der Geschäftsverbindung, auch dann, wenn eine Bezahlung durch Dritte erfolgt. Abweichende Bedingungen bedürfen der schriftlichen Bestätigung des Auftragnehmers. Die allgemeinen Geschäftsbedingungen bleiben bei Unwirksamkeit einzelner Bestimmungen im Übrigen verbindlich.<br/>
              <br/>
              2. Preise<br/>
              2.1. Die Berechnung der zahntechnischen Leistungen erfolgt zu den am Tage der Lieferung laut Preisliste gültigen Preisen zuzüglich der gesetzlichen Mehrwertsteuer.<br/>
              2.2. Kostenvoranschläge beziehen sich auf die am Tage der Ausstellung gültige Preisliste. Sie berücksichtigen nur vorhersehbare Aufwendungen und sind nur in schriftlicher Form verbindlich. Erhöhungen bis 15 % werden vom Auftraggeber ohne vorherige Rückfrage anerkannt. Bei Erhöhungen über 15 % erfolgt vor Beginn der Arbeit Abstimmung mit dem Auftraggeber. Änderungen der Preise für gesondert zu berechnende Materialien (z.B. Zähne, Edelmetall u.a.) verändern den Kostenvoranschlag in jedem Fall.<br/>
              <br/>
              3. Lieferzeit<br/>
              3.1. Lieferfristen werden nach bestem Vermögen angegeben. Bei Überschreitung der Lieferfrist kann der Auftraggeber nur im Falle des Leistungsverzuges des Auftragnehmers oder der von ihm zu vertretenden Unmöglichkeit vom Vertrage zurücktreten oder Schadensersatz verlangen.<br/>
              <br/>
              4. Versand<br/>
              4.1. Der Versand erfolgt auf Kosten und Gefahr des Auftraggebers.<br/>
              <br/>
              5. Haftung<br/>
              5.1. Der Auftraggeber hat die Arbeiten sofort nach Empfang auf die Richtigkeit und Vollständigkeit zu überprüfen. Beanstandungen sind vom Auftraggeber unverzüglich schriftlich anzuzeigen. Der Auftraggeber hat die für eine Nachbesserung bzw. Ersatzlieferung erforderlichen Arbeitsmodelle zur Verfügung zu stellen. Bei Passungenauigkeiten muss die Mängelrüge innerhalb von 10 Tagen seit Empfang der Arbeit unter Vorlage der Erstmodelle erfolgen; neue Modelle bzw. Abformungen sind beizufügen bzw. unverzüglich nachzureichen.<br/>
              5.2. Gewährleistungsansprüche sind auf das Recht auf Nachbesserung oder Ersatzlieferung beschränkt; die Entscheidung hierüber bleibt dem Auftragnehmer vorbehalten. Bei Fehlschlägen der Nachbesserung oder Ersatzlieferung hat der Auftraggeber das Recht, die Vergütung herabzusetzen oder vom Vertrage zurückzutreten.<br/>
              5.3. Schadensersatzansprüche sind ausgeschlossen, soweit sie nicht auf einer grob fahrlässigen Vertragsverletzung des Auftragnehmers oder auf einer vorsätzlichen oder grob fahrlässigen Vertragsverletzung eines gesetzlichen Vertreters oder Erfüllungsgehilfen des Auftragnehmers beruhen.<br/>
              <br/>
              6. Arbeitsunterlagen<br/>
              6.1. Alle Arbeiten werden mit großer Sorgfalt angefertigt. Der Auftragnehmer hat jedoch keinen Einfluss auf die Qualität der eingesandten Modelle und Abformungen. Diese Unterlagen sind für den Sitz im Munde von entscheidender Bedeutung. Arbeitsunterlagen, die mangelhaft erscheinen, können daher unter Rücksprache und Abstimmung mit dem Auftraggeber zurückgesandt werden. Für die Folgen fehlerhafter Modelle und Abformungen muss in jedem Falle der Auftraggeber einstehen.<br/>
              <br/>
              7. Material- und Zubehörteilstellung<br/>
              7.1. Vom Auftraggeber angelieferte Materialien (Edelmetall, Zähne etc.) oder Zubehörteile (Fertigteile, z.B. Geschiebe, Gelenke etc.) können mit einem handelsüblichen Verarbeitungszuschlag belegt werden. Misserfolge aufgrund fehlerhafter vom Auftraggeber angelieferter Materialien oder Zubehörteile gehen nicht zu Lasten des Auftragnehmers. Für die Folgen fehlerhafter Modelle und Abformungen muss in jedem Falle der Auftraggeber einstehen.<br/>
              <br/>
              8. Zahlung<br/>
              8.1. Die Rechnungen sind zahlbar innerhalb von 10 - 14 Tagen nach Rechnungseingang. Schecks gelten erst mit Einlösung als Zahlung. Bei Zahlungsverzug können Verzugszinsen in Höhe von 3 % über dem jeweiligen Diskontsatz der Deutschen Bundesbank berechnet werden.<br/>
              8.2. Gegen Zahlungsansprüche des Auftragnehmers kann der Auftraggeber nur mit unbestrittenen und rechtskräftig festgestellten Forderungen aufrechnen.<br/>
              <br/>
              9. Eigentumsvorbehalt<br/>
              9.1. An sämtlichen gelieferten Arbeiten wird das Eigentum vorbehalten bis zur vollständigen Bezahlung aller Forderungen, auch der Nebenforderungen, aus der Geschäftsverbindung.<br/>
              9.2. Mit der Auftragserteilung tritt der Auftraggeber Forderungen, die er in Ausübung seiner Berufs- oder Erwerbstätigkeit erworben hat, in Höhe des gesamten Laborauftrages an den Auftragnehmer ab.<br/>
              <br/>
              10. Erfüllungsort und Gerichtsstand<br/>
              10.1. Erfüllungsort für die Lieferung und Zahlung ist der Sitz des Labors.<br/>
              10.2. Gerichtsstand ist der Sitz des Labors, sofern a) die im Klagewege in Anspruch zu nehmende Partei nach Vertragsschluss ihren Wohnsitz oder gewöhnlichen Aufenthaltsort aus dem Geltungsbereich der Bundesrepublik verlegt oder ihr Wohnsitz oder gewöhnlicher Aufenthalt zum Zeitpunkt der Klageerhebung nicht bekannt ist, b) Ansprüche im Wege des Mahnverfahrens geltend gemacht werden.<br/>
              <br/>
              Zahnwerkstatt Gernot Goetz GmbH<br/>
              Stand 02/2015<br/>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ZahnwerkstattAGBPage",
  mounted() {
    this.trackPageview();
  },
  methods: {
    trackPageview() {
      this.$gtag('config', 'G-FMX73Y0556', {'page_path': this.$route.path});
    },
  }
}
</script>

<style scoped>

</style>
