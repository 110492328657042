<template>
  <div class="patients-page">
    <div class="section section-infos first-section">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">Schöne Zähne, ein sicheres Gefühl – seit 1991</h2>
            <h5 class="description">
              Besser zu sein als andere und mehr zu leisten als nötig: Das war schon immer unser Antrieb.<br />
              <br />
              Seit 1991 vertrauen uns viele Zahnärzte. Darauf sind wir besonders stolz,
              denn nichts unterstreicht unser Können und unsere Kompetenz mehr, als eine
              langjährige und partnerschaftliche Zusammenarbeit.
              Sie können sich darauf verlassen, dass wir immer vollen Einsatz bringen - für Sie als Behandler,
              für Ihre Patienten und deren Wünsche in puncto Zahnersatz!
            </h5>
          </div>
        </div>
        <div class="separator separator-primary"></div>
        <div class="section-story-overview">
          <div class="row">
            <div class="col-md-6">
              <div
                  class="image-container image-left"
                  style="background-image: url('img/zahnwerkstatt/dentists/dentists_1.jpg')"
              >
                <!-- First image on the left side -->
              </div>
            </div>
            <div class="col-md-5">
              <!-- First image on the right side, above the article -->
              <a href="/#/caseExamples">
              <div
                  class="image-container image-right"
                  style="background-image: url('img/zahnwerkstatt/dentists/dentists_2.jpg')"
              ></div></a>
            </div>
            <div class="col-md-8 ml-auto mr-auto">
              <h3>
                Unsere Stärken
              </h3>
              <p>
                <b>Unser Leistungsspektrum umfasst:</b>
              </p>
              <ul>
                <li>Kronen- und Brückentechnik</li>
                <ul class="sublist">
                  <li>in Vollkeramik (Zirkon)</li>
                  <li>in VMK  aus Biolegierungen</li>
                  <li>in Crom-Kobald Legierung (NEM)</li>
                </ul>
                <li>OBI, spezialisiert für rekonstruktive Zahntechnik, Ästhetik und Funktion (bioästhetische Zahnheilkunde)</li>
                <li>Inlays aus Vollkeramik (e.max®) oder aus Bio-Goldlegierung</li>
                <li>Veneers aus Vollkeramik (e.max®)</li>
                <li>Non Invasive Veeners (Platinfolientechnik)</li>
                <li>Hybridabutments aus Zirkonoxid oder e.max® für alle gängigen Implantatsysteme</li>
                <li>Suprakonstruktionen aller Implantatsysteme (festsitzend, Stege, auch mit Riegel, Teleskope)</li>
              </ul>
              <p>
                Dazu bieten wir individuelle Farbbestimmung und -gestaltung mit Patient bei uns im Labor.
              </p>
              <p>
                <b>Kombinierter Zahnersatz:</b><br />
                Teleskope, Geschiebe, Stege, Riegel Kugelkopf, Locatoren.…
              </p>
              <p>
                <b>Schienen:</b><br />
                MAGO-Schienen (Maxillary Anterior Guided Orthosis)
                Tiefgezogen, gegossen oder CAD/CAM, Sportmundschutz
              </p>
              <p>
                <b>Totalprothetik:</b><br />
                Standardausführung oder mit individualisierten Zähnen, auch nach Prof. Gutowski
              </p>
              <p>
                <b>Eingesetzte Technik:</b>
              </p>
              <ul>
                <li>CAD/CAM In-Haus-Fertigung</li>
                <li>E-Max Presskeramik</li>
                <li>Laser- Schweißen</li>
                <li>Gold & NEM Vakuum Druckguß oder Flammenguß (bei Wunsch nach Mondphasen)</li>
                <li>Artikulatorsysteme: Panadent - SAM - Artex - Protar</li>
              </ul>
              <p>
                <b>Leihservice für Zahnärzte:</b>
              </p>
              <ul>
                <li>Akkubetriebenes Winkelstück für Hausbesuche</li>
                <li>Ersatzhandstück (z.B. bei Ausfall durch Reparatur)</li>
                <li>KaVo Coronaflex - Kronen und Brückenheber</li>
                <li>Diverse Schaumodelle</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section section-strengths">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">Fortbildung</h2>
          </div>
        </div>
        <div class="separator separator-primary"></div>
        <div class="section-story-overview">
          <div class="row">
            <div class="col-md-6">
              <div
                  class="image-container image-left"
                  style="background-image: url('img/zahnwerkstatt/dentists/dentists_3.jpg')"
              >
              </div>
            </div>
            <div class="col-md-5">
              <div
                  class="image-container image-right"
                  style="background-image: url('img/zahnwerkstatt/dentists/dentists4.jpg')"
              ></div>
            </div>
            <div class="col-md-8 ml-auto mr-auto">
              <p>
                Darüber hinaus sehen wir Fortbildung als Teil des Laboralltags und finden es sehr wichtig,
                fachübergreifende Zusammenhänge zu verstehen.<br />
                <br />
                Hier ein Überblick über die wichtigsten Kurse die wir besucht haben:<br />
              </p>
              <p>
                <b>Fachübergreifend:</b>
              </p>
              <ul class="text-body">
                <li>Prof. Gutowski</li>
                <li>Prof. Slavicek</li>
                <li>Prof. Edelhoff</li>
                <li>Dr. Reusch</li>
                <li>Dr. Bücking</li>
                <li>die komplette OBI Kursserie um Dr. Gehrig/Dr. Congost</li>
              </ul>
              <p>
                <b>Zahntechnisch:</b>
              </p>
              <ul>
                <li>Prof. Tanaka</li>
                <li>Willi Geller</li>
                <li>Gerald Ubassy</li>
                <li>Enrico Steger</li>
                <li>Axel Gütges</li>
                <li>Jürgen Freitag</li>
                <li>Paul Fichter</li>
                <li>Bertrand Thiévent</li>
              </ul>
              <p>
                Außerdem haben wir bei unserem Umzug 2007 in die modernen, lichtdurchfluteten Räume in der Ehbachstraße
                11, gleich die Möglichkeit für eigene Fortbildungsveranstaltungen geschaffen.
                In unseren Seminarräumen, mit bis zu 35 Sitzplätzen und 12 zahntechnischen Arbeitsplätzen, haben
                wir inzwischen über 20 Veranstaltungen für  Zahntechniker,
                Zahnärzte und Zahnarzthelferinnen mit großem Erfolg durchgeführt.
                <br />
                Wir sind immer am Puls der Zeit!<br />
                <br />
                Seien sie unser Gast und besuchen Sie uns in Unteruhldingen
                am Bodensee.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section section-courses">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">Kurse</h2>
            <h5 class="description">
              Fortbildung ist ein wesentlicher Bestandteil unserer Philosophie.
            </h5>
            <p class="text-body">
              Nur durch stetes Weiterbilden sind wir in der Lage, unseren Patienten,
              die besten zahntechnischen Verfahren,
              optimale Materialien und neueste zahnmedizinische Erkenntnisse zu bieten.
              So finden in unseren eigenen Kursräumen regelmäßig Kurse für Zahntechniker,
              Zahnärzte und Zahnarzthelferinnen statt.
              Viele namhafte Referenten begeisterten die Teilnehmer
              und führten uns schon durch weite Gebiete der Zahntechnik.
            </p>
          </div>
        </div>
        <div class="separator separator-primary"></div>
        <div class="section-story-overview">
          <div class="row">
            <div class="col-md-4">
              <nav>
                <div class="nav nav-pills flex-column" id="nav-tab" role="tablist">
                  <button class="nav-link active custom-pill" id="nav-kurs1-tab" data-bs-toggle="tab" data-bs-target="#nav-kurs1" type="button" role="tab" aria-controls="nav-kurs1" aria-selected="true">Bertrand Thievent</button>
                  <button class="nav-link custom-pill" id="nav-kurs2-tab" data-bs-toggle="tab" data-bs-target="#nav-kurs2" type="button" role="tab" aria-controls="nav-kurs2" aria-selected="false">Fräskurs mit Hafner und Schick</button>
                  <button class="nav-link custom-pill" id="nav-kurs3-tab" data-bs-toggle="tab" data-bs-target="#nav-kurs3" type="button" role="tab" aria-controls="nav-kurs3" aria-selected="false">Gütges ,Axel 1. Kurs</button>
                  <button class="nav-link custom-pill" id="nav-kurs4-tab" data-bs-toggle="tab" data-bs-target="#nav-kurs4" type="button" role="tab" aria-controls="nav-kurs4" aria-selected="false">Jürgen Freitag</button>
                  <button class="nav-link custom-pill" id="nav-kurs5-tab" data-bs-toggle="tab" data-bs-target="#nav-kurs5" type="button" role="tab" aria-controls="nav-kurs5" aria-selected="false">M-Implant Markteinführung</button>
                  <button class="nav-link custom-pill" id="nav-kurs6-tab" data-bs-toggle="tab" data-bs-target="#nav-kurs6" type="button" role="tab" aria-controls="nav-kurs6" aria-selected="false">Zahnarzthelferinnenkurs 3</button>
                </div>
              </nav>
            </div>
            <div class="tab-content col-md-8 justify-content-center" id="nav-tabContent">
              <div class="tab-pane fade show active" id="nav-kurs1" role="tabpanel" aria-labelledby="nav-kurs1-tab" tabindex="0">
                <el-carousel height="350px">
                  <el-carousel-item>
                    <div class="card">
                      <img src="img/zahnwerkstatt/kurse/kurs1/kurs1_1.jpg" alt="Card image cap" class="card-img-top">
                    </div>
                  </el-carousel-item>
                  <el-carousel-item>
                    <div class="card">
                      <img src="img/zahnwerkstatt/kurse/kurs1/kurs1_2.jpg" alt="Card image cap" class="card-img-top">
                    </div>
                  </el-carousel-item>
                  <el-carousel-item>
                    <div class="card">
                      <img src="img/zahnwerkstatt/kurse/kurs1/kurs1_3.jpeg" alt="Card image cap" class="card-img-top">
                    </div>
                  </el-carousel-item>
                  <el-carousel-item>
                    <div class="card">
                      <img src="img/zahnwerkstatt/kurse/kurs1/kurs1_4.jpg" alt="Card image cap" class="card-img-top">
                    </div>
                  </el-carousel-item>
                  <el-carousel-item>
                    <div class="card">
                      <img src="img/zahnwerkstatt/kurse/kurs1/kurs1_5.jpg" alt="Card image cap" class="card-img-top">
                    </div>
                  </el-carousel-item>
                </el-carousel>
              </div>
              <div class="tab-pane fade" id="nav-kurs2" role="tabpanel" aria-labelledby="nav-kurs2-tab" tabindex="0">
                <el-carousel height="350px">
                  <el-carousel-item>
                    <div class="card">
                      <img src="img/zahnwerkstatt/kurse/kurs2/kurs2_1.jpg" alt="Card image cap" class="card-img-top">
                    </div>
                  </el-carousel-item>
                  <el-carousel-item>
                    <div class="card">
                      <img src="img/zahnwerkstatt/kurse/kurs2/kurs2_2.jpg" alt="Card image cap" class="card-img-top">
                    </div>
                  </el-carousel-item>
                  <el-carousel-item>
                    <div class="card">
                      <img src="img/zahnwerkstatt/kurse/kurs2/kurs2_3.jpg" alt="Card image cap" class="card-img-top">
                    </div>
                  </el-carousel-item>
                  <el-carousel-item>
                    <div class="card">
                      <img src="img/zahnwerkstatt/kurse/kurs2/kurs2_4.jpg" alt="Card image cap" class="card-img-top">
                    </div>
                  </el-carousel-item>
                  <el-carousel-item>
                    <div class="card">
                      <img src="img/zahnwerkstatt/kurse/kurs2/kurs2_5.jpg" alt="Card image cap" class="card-img-top">
                    </div>
                  </el-carousel-item>
                </el-carousel>
              </div>
              <div class="tab-pane fade" id="nav-kurs3" role="tabpanel" aria-labelledby="nav-kurs3-tab" tabindex="0">
                <el-carousel height="350px">
                  <el-carousel-item>
                    <div class="card">
                      <img src="img/zahnwerkstatt/kurse/kurs3/kurs3_1.jpg" alt="Card image cap" class="card-img-top">
                    </div>
                  </el-carousel-item>
                  <el-carousel-item>
                    <div class="card">
                      <img src="img/zahnwerkstatt/kurse/kurs3/kurs3_2.jpeg" alt="Card image cap" class="card-img-top">
                    </div>
                  </el-carousel-item>
                  <el-carousel-item>
                    <div class="card">
                      <img src="img/zahnwerkstatt/kurse/kurs3/kurs3_3.jpg" alt="Card image cap" class="card-img-top">
                    </div>
                  </el-carousel-item>
                  <el-carousel-item>
                    <div class="card">
                      <img src="img/zahnwerkstatt/kurse/kurs3/kurs3_4.jpg" alt="Card image cap" class="card-img-top">
                    </div>
                  </el-carousel-item>
                  <el-carousel-item>
                    <div class="card">
                      <img src="img/zahnwerkstatt/kurse/kurs3/kurs3_5.jpg" alt="Card image cap" class="card-img-top">
                    </div>
                  </el-carousel-item>
                </el-carousel>
              </div>
              <div class="tab-pane fade" id="nav-kurs4" role="tabpanel" aria-labelledby="nav-kurs4-tab" tabindex="0">
                <el-carousel height="350px">
                  <el-carousel-item>
                    <div class="card">
                      <img src="img/zahnwerkstatt/kurse/kurs4/kurs4_1.jpg" alt="Card image cap" class="card-img-top">
                    </div>
                  </el-carousel-item>
                  <el-carousel-item>
                    <div class="card">
                      <img src="img/zahnwerkstatt/kurse/kurs4/kurs4_2.jpg" alt="Card image cap" class="card-img-top">
                    </div>
                  </el-carousel-item>
                  <el-carousel-item>
                    <div class="card">
                      <img src="img/zahnwerkstatt/kurse/kurs4/kurs4_3.jpg" alt="Card image cap" class="card-img-top">
                    </div>
                  </el-carousel-item>
                  <el-carousel-item>
                    <div class="card">
                      <img src="img/zahnwerkstatt/kurse/kurs4/kurs4_4.jpeg" alt="Card image cap" class="card-img-top">
                    </div>
                  </el-carousel-item>
                  <el-carousel-item>
                    <div class="card">
                      <img src="img/zahnwerkstatt/kurse/kurs4/kurs4_5.jpg" alt="Card image cap" class="card-img-top">
                    </div>
                  </el-carousel-item>
                </el-carousel>
              </div>
              <div class="tab-pane fade" id="nav-kurs5" role="tabpanel" aria-labelledby="nav-kurs5-tab" tabindex="0">
                <el-carousel height="350px">
                  <el-carousel-item>
                    <div class="card">
                      <img src="img/zahnwerkstatt/kurse/kurs5/kurs5_2.jpg" alt="Card image cap" class="card-img-top">
                    </div>
                  </el-carousel-item>
                  <el-carousel-item>
                    <div class="card">
                      <img src="img/zahnwerkstatt/kurse/kurs5/kurs5_3.jpg" alt="Card image cap" class="card-img-top">
                    </div>
                  </el-carousel-item>
                  <el-carousel-item>
                    <div class="card">
                      <img src="img/zahnwerkstatt/kurse/kurs5/kurs5_4.jpg" alt="Card image cap" class="card-img-top">
                    </div>
                  </el-carousel-item>
                  <el-carousel-item>
                    <div class="card">
                      <img src="img/zahnwerkstatt/kurse/kurs5/kurs5_5.jpg" alt="Card image cap" class="card-img-top">
                    </div>
                  </el-carousel-item>
                </el-carousel>
              </div>
              <div class="tab-pane fade" id="nav-kurs6" role="tabpanel" aria-labelledby="nav-kurs6-tab" tabindex="0">
                <el-carousel height="350px">
                  <el-carousel-item>
                    <div class="card">
                      <img src="img/zahnwerkstatt/kurse/kurs6/kurs6_1.jpg" alt="Card image cap" class="card-img-top">
                    </div>
                  </el-carousel-item>
                  <el-carousel-item>
                    <div class="card">
                      <img src="img/zahnwerkstatt/kurse/kurs6/kurs6_2.jpg" alt="Card image cap" class="card-img-top">
                    </div>
                  </el-carousel-item>
                  <el-carousel-item>
                    <div class="card">
                      <img src="img/zahnwerkstatt/kurse/kurs6/kurs6_3.jpg" alt="Card image cap" class="card-img-top">
                    </div>
                  </el-carousel-item>
                  <el-carousel-item>
                    <div class="card">
                      <img src="img/zahnwerkstatt/kurse/kurs6/kurs6_4.jpg" alt="Card image cap" class="card-img-top">
                    </div>
                  </el-carousel-item>
                </el-carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section section-mobileLab">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">Mobiles Labor</h2>
            <h5 class="description">
              Für spezielle Fälle sind wir für Ihre Patienten auch direkt bei Ihnen vor Ort im Einsatz.<br >
              <br >
              So können wir in unserem mobilen Labor z.B. schwierige Einzelkronen,
              Restaurationen oder Fertigstellungen bei/in der Praxis durchführen.
              >>> für immobile Patienten ein hervorragendes Angebot.<br >
              <br >
              Fragen Sie uns - wir haben immer eine Lösung.
            </h5>
          </div>
        </div>
        <div class="separator separator-primary"></div>
        <div class="section-story-overview">
          <div class="row">
            <div class="col-md-6">
              <div
                  class="image-container image-left"
                  style="background-image: url('img/zahnwerkstatt/mobil/mobil_1.jpg')"
              >
                <!-- First image on the left side -->
              </div>
              <!-- Second image on the left side of the article -->
            </div>
            <div class="col-md-5">
              <!-- First image on the right side, above the article -->
              <div
                  class="image-container image-right"
                  style="background-image: url('img/zahnwerkstatt/mobil/mobil_2.jpeg')"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section mt-c1n" id="carouselMobileLab">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-10">
            <el-carousel height="500px">
              <el-carousel-item>
                <img class="d-block stretch-image" src="img/zahnwerkstatt/mobil/mobil_3.jpg" alt="First slide" />
              </el-carousel-item>
              <el-carousel-item>
                <img class="d-block stretch-image" src="img/zahnwerkstatt/mobil/mobil_4.jpg" alt="Second slide" />
              </el-carousel-item>
              <el-carousel-item>
                <img class="d-block stretch-image" src="img/zahnwerkstatt/mobil/mobil_5.jpg" alt="Third slide" />
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Carousel, CarouselItem} from "element-ui";

export default {
  name: "ZahnwerkstattPatientsPage",
  components: {
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem
  },
  mounted() {
    this.trackPageview();
  },
  methods: {
    trackPageview() {
      this.$gtag('config', 'G-FMX73Y0556', {'page_path': this.$route.path});
    },
  }
}
</script>

<style>
.custom-pill {
  border:none;
  background-color: white;
  border-radius: 30px!important;
  margin: 5px;
}

.custom-pill.active {
  background-color: #444!important;
  border-radius: 30px!important;
  color: #FFFFFF;
  margin: 5px;
}

.sublist {
  line-height: 1.59em;
  font-weight: 400;
  font-size: 1.0em;
}
</style>
