<template>
  <div class="impressumPage">
    <div class="section section-about-us first-section">
      <div class="container">
        <div class="row">
          <div class="col-md-12 ml-auto mr-auto text-center">
            <h2 class="title">Stellenangebote</h2>
          </div>
        </div>
        <div class="separator separator-primary"></div>
        <div class="section-story-overview">
          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title">Kunststofftechniker*in (m/w)</h4>
                  <p class="card-text">
                    Seit 1991 fertigen wir in Unteruhldingen hochwertigen
                    Zahnersatz für unsere anspruchsvollen Zahnärzte.
                    Zum nächstmöglichen Termin suchen wir, ein*e Vollzeit- oder Teilzeitmitarbeiter*in, für die Kunststofftechnik im Dentallabor.<br/>
                    <br/>
                    <b>Ihr Aufgabengebiet:</b>
                    <ul class="sublist">
                      <li>Total- /Teilprothesen</li>
                      <li>Interimsprothesen</li>
                      <li>Schienentechnik</li>
                      <li>Prothesen-Reparaturen</li>
                    </ul>

                    <b>Das bringen Sie mit:</b>
                    <ul class="sublist">
                      <li>abgeschl. Zahntechnikausbildung
                        oder Quereinsteiger mit 3 J. Berufserfahrung</li>
                      <li>Freude am exakten Arbeiten</li>
                      <li>Organisationstalent</li>
                    </ul>
                    Fragen? Dann rufen Sie uns gerne an!
                    Wir freuen uns über Ihre Bewerbung inkl. Lebenslauf.
                  </p>
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title">Keramiktechniker*in (m/w)</h4>
                  <p class="card-text">
                    In einer der wirtschaftsstärksten Regionen Deutschlands gesucht !!<br/>
                    - die dazu noch einen sehr hohen Freizeitwert bietet -<br/>
                    <br/>
                    Hier stellen wir seit 1991 hochwertigen Zahnersatz für unsere anspruchsvollen Zahnärzte her.
                    Unsere Arbeitsschwerpunkte reichen von klassischer Hochgold-VMK und Teleskop-Kombitechnik
                    bis hin zur individuell im Labor angepassten e.max®- und Zirkon-Vollkeramikarbeiten.
                    Wir pflegen in unseren hellen und freundlichen Laborräumen ein familiäres Betriebsklima - dabei
                    legt unser engagiertes Team großen Wert auf Funktion, Ästhetik und Qualität.<br/>
                    <br/>
                    SIE  <br/>
                    <ul class="sublist">
                      <li>sind mit Leib und Seele Zahntechniker/in.</li>
                      <li>haben ein Auge für Ästhetik.</li>
                      <li>besitzen eine sichere Hand für Präzision.</li>
                      <li>haben Freude an schönen Zähnen.</li>
                    </ul>
                    DANN  bieten wir  IHNEN  einen zukunftssicheren Arbeitsplatz, am schönen Bodensee!<br/>
                    Eine spätere Beteiligung ist möglich.<br/>
                    <br/>
                    Wir freuen uns über Ihre vollständigen Bewerbungsunterlagen, einschließlich Ihren Tätigkeitsschwerpunkten, der letzten Jahren.<br/>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ZahnwerkstattAGBPage",
  mounted() {
    this.trackPageview();
  },
  methods: {
    trackPageview() {
      this.$gtag('config', 'G-FMX73Y0556', {'page_path': this.$route.path});
    },
  }
}
</script>

<style scoped>

</style>
