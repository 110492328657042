<template>
  <div class="patients-page">
    <div class="section section-patients first-section">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">Allgemeine Informationen</h2>
            <h5 class="description">
              Sehr geehrter Patient,<br>
              <br>
              seit 1991 fertigen wir hochpräzisen Zahnersatz, der sich über viele Jahre
              hinweg wie ein natürlicher Teil Ihres Körpers in Ihr Gebiss einfügt.
              Neueste Materialien und Fertigungstechniken stehen bei uns in Verbindung mit traditioneller
              Handwerkskunst. Wir sind in der Lage, mit viel Sorgfalt und Fingerspitzengefühl, auch
              die kompliziertesten Fälle zu lösen.
              Individuell und ästhetisch – eine Einzelanfertigung speziell für Sie.<br>
              <br>
              Wir machen nicht einfach nur Zahnersatz.
              Unsere Leidenschaft ist es, schöne Zähne zu fertigen.<br>
              <br>
              Ihr Gernot Goetz
              mit dem Team der Zahnwerkstatt.
            </h5>
          </div>
        </div>
        <div class="separator separator-primary"></div>
        <div class="section-story-overview">
          <div class="row">
            <div class="col-md-6">
              <div
                  class="image-container image-left"
                  style="background-image: url('img/zahnwerkstatt/patients/patients_3.jpg')"
              >
                <!-- First image on the left side -->
              </div>
              <!-- Second image on the left side of the article -->
              <div
                  class="image-container mt-c1"
                  style="background-image: url('img/zahnwerkstatt/patients/patients_2.jpg')"
              ></div>
            </div>
            <div class="col-md-6">
              <!-- First image on the right side, above the article -->
              <div
                  class="image-container image-right"
                  style="background-image: url('img/zahnwerkstatt/patients/patients_1.jpg')"
              ></div>
              <h3>
                Unsere Stärken
              </h3>
              <ul class="text-body">
                <li>Kronen- und Brückentechnik 100% METALLFREI</li>
                <li>Kronen- und Brückentechnik in VMK mit Biolegierungen</li>
                <li>OBI, spezialisiert für rekonstruktive Zahntechnik, Ästhetik und Funktion (bioästhetische Zahnheilkunde)</li>
                <li>Individuelle Zahnfarbgestaltung am Patienten</li>
                <li>Inlays 100% METALLFREI</li>
                <li>Inlays, klassisch in Biolegierungen</li>
                <li>Veneers</li>
                <li>Implantatkronen und Brücken jeglicher Art <br> (z.B. Hybridabutments)</li>
                <li>Kombinierter, herausnehmbarer  Zahnersatz <br> (z.B. Stege)</li>
                <li>Verschiedene Schienen zum Schutz gegen Knirschen</li>
                <li>Sportmundschutz</li>
                <li>Totalprothesen (konfektionierte oder mit individualisierten Zähnen)</li>
              </ul>
              <p class="text-body">
                Zusätzlich bieten wir Fortbildungskurse für Zahnärzte, Helferinnen und Techniker im Labor
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section section-overseas">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">Auslandszahnersatz</h2>
          </div>
        </div>
        <div class="separator separator-primary"></div>
        <div class="section-story-overview">
          <div class="row">
            <div class="col-md-6">
              <div
                  class="image-container image-left"
                  style="background-image: url('img/zahnwerkstatt/patients/patients_4.jpg')"
              >
                <!-- First image on the left side -->
                <p class="blockquote blockquote-primary custom-quote">
                  "Es ist unklug, zu viel zu bezahlen, aber es ist noch schlechter, zu wenig zu bezahlen.
                  Wenn Sie zu viel bezahlen, verlieren Sie etwas Geld, das ist alles.
                  Wenn Sie dagegen zu wenig bezahlen, verlieren Sie manchmal alles, da der gekaufte Gegenstand
                  die ihm zugedachte Aufgabe nicht erfüllen kann.
                  Das Gesetz der Wirtschaft verbietet es, für wenig Geld viel Wert zu erhalten.
                  Nehmen Sie das niedrigste Angebot an, müssen Sie für das Risiko, das Sie eingehen, etwas hinzurechnen.
                  Und wenn Sie das tun, dann haben Sie auch genug Geld, um für etwas Besseres zu bezahlen."
                  <br />
                  <br />
                  <small>-John Ruskin (1819 – 1900)</small>
                </p>
              </div>
              <!-- Second image on the left side of the article -->
              <div
                  class="image-container mt-c3"
                  style="background-image: url('img/zahnwerkstatt/patients/patients_5.jpg')"
              ></div>
            </div>
            <div class="col-md-5">
              <!-- First image on the right side, above the article -->
              <div
                  class="image-container image-right"
                  style="background-image: url('img/zahnwerkstatt/patients/patients_6.jpg')"
              ></div>
              <h3>
                Zahnersatz aus dem Ausland – eine Alternative?
              </h3>
              <p>
                In Ländern wie China gibt es noch kein einheitliches Ausbildungssystem für Zahntechniker.
                In der Regel wird angelernten Arbeitskräften nur Teilschritte beigebracht.
                Es arbeiten an der gleichen Krone mehrere Menschen, meistens im Schichtbetrieb.
                Ästhetische Ergebnisse sind so nicht möglich.
              </p>
              <p>
                Dies ist nicht zu vergleichen mit dem dualen Ausbildungssystem in Deutschland,
                das weltweit große Anerkennung genießt.
                Die Ausbildung zum Zahntechniker dauert in Deutschland 3 1/2 Jahre und vermittelt umfassende
                Kenntnisse des stomotognathen Systems
                (Kiefer- und Mundbereich mit den Funktionen des gesamten Kauorgans).
                Unsere Mitarbeiter haben diese komplexen Zusammenhänge erlernt und sind als Spezialisten
                in der Lage, komplette Restaurationen selbstständig herzustellen.
              </p>
              <p>
                Meine Meinung:
                Beim Kauf eines Produktes, das oft jahrelang im eigenen Körper bleibt und dort unbemerkt
                seine Aufgabe erfüllen sollte, würde ich die Kaufentscheidung nicht unbedingt vom Preis abhängig machen.
                Wenn man sich für die kostengünstigere Variante aus dem Ausland entscheidet, bezahlt man zunächst weniger Geld, geht aber gleichtzeitig auch ein höheres Risiko ein.
                Wegen der oft schlechteren Qualität können schon nach wenigen Jahren Reparaturen anfallen und so summieren sich die Kosten nicht selten höher als erwartet.
                <br />
                <br />
                Ihr  Gernot Goetz,
                <br />
                Zahntechnikermeister
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ZahnwerkstattPatientsPage",
  mounted() {
    this.trackPageview();
  },
  methods: {
    trackPageview() {
      this.$gtag('config', 'G-FMX73Y0556', {'page_path': this.$route.path});
    },
  }
}
</script>

<style scoped>

.custom-quote {
  width: auto !important;
}

@media (max-width: 375px) {
  .custom-quote {
    width: auto !important;
  }
  .mt-c3 {
    margin-top: 40rem !important;
  }
}

</style>
